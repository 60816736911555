import React, { useState } from 'react';
import FancyInput from './FancyInput';


const WithCopy = ({value}) => {
  const [copyStatus, setCopyStatus] = useState(false)

  return (
    <div className="flex items-center">
      <FancyInput
        id="token"
        type="text"
        value={value}
        disabled
      />
      
      <button
        type="button"
        id="copyBtn"
        onClick={() => {
          navigator.clipboard.writeText(value)
          setCopyStatus(true)
        }}
        className="redBtn"
      >
        COPY
      </button>
      {copyStatus && <input type="checkbox" class="form-checkbox h-5 w-5 rounded-lg text-green-600" checked></input>}
    
    </div>
  );
}

export default WithCopy;